// Tabs.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { debounce } from 'lodash';

class Tabs extends React.PureComponent {
  state = {
    indicatorStyle: {},
  };

  componentDidMount() {
    this.updateIndicatorState();
  }

  componentDidUpdate(prevProps) {
    const { value: prevValue } = prevProps;
    const { value } = this.props;
    if (value !== prevValue) {
      this.updateIndicatorState();
    }
    this.updateIndicatorState(this.props);
    window.addEventListener('resize', this.resizeDebounce);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeDebounce);
  }

  resizeDebounce = debounce(() => this.updateIndicatorState(this.props), 500); // TODO: remote config

  updateIndicatorState = () => {
    const { value } = this.props;
    const {
      tabs,
      tabsBoundingClientRect,
      tab,
      tabBoundingClientRect,
      labelBoundingClientRect,
    } = this.getBoundingClientRects(value);

    let tabOffsetLeft = 0;

    if (tabBoundingClientRect && tabsBoundingClientRect) {
      tabOffsetLeft =
        tab.offsetLeft +
        tabBoundingClientRect.width / 2 -
        labelBoundingClientRect.width / 2;

      // for Leaderboard, scroll to position where active tab will be in center of screen
      const centerTabDistance =
        tabOffsetLeft -
        tabsBoundingClientRect.width / 2 +
        labelBoundingClientRect.width / 2;
      tabs.scrollTo?.({
        left: Math.max(centerTabDistance, 0),
        behavior: 'smooth',
      });
    }

    const indicatorStyle = {
      left: tabOffsetLeft,
      width: labelBoundingClientRect
        ? Math.floor(labelBoundingClientRect.width)
        : 0,
    };

    if (
      indicatorStyle.left !== this.state.indicatorStyle.left ||
      indicatorStyle.width !== this.state.indicatorStyle.width
    ) {
      this.setState({ indicatorStyle });
    }
  };

  getBoundingClientRects = value => {
    const tabs = this.tabsRef;
    const tabsBoundingClientRect = tabs?.getBoundingClientRect();
    let tabBoundingClientRect;
    let labelBoundingClientRect;
    let tab = null;
    let label = null;

    if (this.tabsRef && value !== false) {
      tab = this.tabsRef.children[this.valueToIndex.get(value)];
      label = tab?.firstChild;
      tabBoundingClientRect = tab?.getBoundingClientRect();
      labelBoundingClientRect = label?.getBoundingClientRect();
    }
    return {
      tabs,
      tabsBoundingClientRect,
      tab,
      tabBoundingClientRect,
      label,
      labelBoundingClientRect,
    };
  };

  handleTabsRef = ref => {
    this.tabsRef = ref;
  };

  render() {
    const {
      children,
      onChange,
      value,
      inline,
      indicatorStyle,
      isScrollable,
      ...otherProps
    } = this.props;

    this.valueToIndex = new Map();

    return (
      <StyledTabs
        ref={this.handleTabsRef}
        isScrollable={isScrollable}
        inline={inline}
        {...otherProps}
      >
        {React.Children.map(children, (child, childIndex) => {
          const childValue =
            child.props.value === undefined ? childIndex : child.props.value;

          this.valueToIndex.set(childValue, childIndex);

          return React.cloneElement(child, {
            onChange,
            childIndex,
            value: childValue,
            selected: childValue === value,
          });
        })}
        <StyledIndicator
          style={{
            ...indicatorStyle,
            ...this.state.indicatorStyle,
          }}
        />
      </StyledTabs>
    );
  }
}

Tabs.propTypes = {
  children: PropTypes.node,
  indicatorStyle: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.any,
  isScrollable: PropTypes.bool,
  inline: PropTypes.bool,
};

Tabs.defaultProps = {
  indicatorStyle: {},
  onChange: () => null,
  isScrollable: false,
  inline: false,
};

const StyledTabs = styled.nav`
  position: relative;
  display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
  justify-content: ${({ isScrollable }) =>
    isScrollable ? 'flex-start' : 'space-around'};
  -webkit-overflow-scrolling: touch;
  ${({ isScrollable }) => {
    if (isScrollable) {
      return `
        overflow-x: scroll;
        scrollbar-width: none;
        ::-webkit-scrollbar {
          display: none;
        }
        ::before {
          content: '';
          width: 16px;
          flex: none;
        }
        ::after {
          content: '';
          width: 16px;
          flex: none;
        }
      `;
    }
  }}
`;

const StyledIndicator = styled.span`
  height: 4px;
  bottom: 0;
  position: absolute;
  background-color: #00d2be;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export default Tabs;
