// BrowseTab.js
import { connect } from 'react-redux';

import { BROWSE_TABS } from '../RemoteConfigKeys.js';
import BrowseTab from '../component/BrowseTab.jsx';
import { replace, push } from '../action/navigationAction.js';
import getMeData from '../selector/getMeData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import getModalData from '../selector/getModalData.js';
import { SEARCH_MODAL_ID } from '../resource/searchConstants.js';

const mapStateToProps = state => {
  const isSearchModalOpened = ['opening', 'display'].includes(
    getModalData(state, SEARCH_MODAL_ID, 'status')
  );
  const shouldRenderSearchBar = !isSearchModalOpened;
  return {
    meId: getMeData(state, 'id'),
    tabs: getRemoteConfigData(state, BROWSE_TABS),
    shouldRenderSearchBar,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    replace: (...args) => dispatch(replace(...args)),
    push: (...args) => dispatch(push(...args)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrowseTab);
